    .react-international-phone-input-container {
        display: flex !important;
        width: 100% !important;
        height: 100% !important;
    }

    .react-international-phone-input {
        width: 100% !important;
        border: none !important;
    }

    .react-international-phone-country-selector {
        button {
            border: none !important;
        }
    }