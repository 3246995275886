.product-variant-summary {
    font-size: 12px;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 33%);

    &__wrapper {
        width: 50px;
        flex-shrink: 0;
        padding: 0 0.5rem;
        display: inline-block;
    }

    &--active {
        outline: 2px solid #121a42;
    }
}