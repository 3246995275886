.product-group {
    margin: 20px 0;
    padding-bottom: 32px;

    &>h2 {
        font-weight: 100;
        margin-bottom: 1rem;
    }

    &__products {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 45%;
    }

    &__touch-screen-scroll {
        display: flex;
        flex-shrink: 0;
        //max-width: 90%;
        width: 100%;
        overflow: scroll;
        flex-wrap: nowrap;
        padding-bottom: 10px;
    }

    &__slider-wrapper {
        max-width: 750px;

        .product-variant__wrapper {
            width: 100% !important;
        }
    }

    &__variant {

        &>p {
            font-size: 1.15rem;
            font-weight: 100;
            margin: 2rem 0;
        }

        &--names {
            @media (max-width: 1265px) {
                display: none;
            }
        }
    }
}