.app {

    @media (max-width: 1200px) {
        overflow: hidden;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    background: #fff;

    &--security {
        background: #f8f8f8;
    }

    .app-content-wrapper {
        //transition: 0.3s;

        &--small {
            @media (max-width: 1200px) and (min-width: 768px) {
                width: calc(100% - 500px);
            }
        }
    }

    .app-sidebar {
        position: relative;

        &__mobile-window {
            display: none;

            @media (max-width: 768px) {
                margin: 0 auto;
                position: fixed;
                bottom: 0px;
                background-color: white;
                z-index: 100000;
                left: 0;
                width: 100%;
                height: 0px;
                overflow: hidden;
                display: none;
                padding: 0 20px;
                //transition: 0.3s;
            }

            &__order-btn {
                background: #353e6d;
                color: white;
                text-decoration: none;
                padding: 10px;
                width: 100% !important;
                display: block;
                margin: auto;
                text-align: center;
                margin-bottom: 20px;
                border-radius: 0.375rem;
                margin-top: 20px;
            }

            .summary {
                height: 300px;
                padding: 0px;
                overflow: scroll;
            }

            &__title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0 20px 0;

                p {
                    margin-bottom: 0px;
                }
            }
        }

        &--right-side {

            @media (max-width: 1200px) and (min-width: 768px) {
                width: 500px;

                .summary--wrapper {
                    position: fixed;
                    height: calc(100% - 80px);
                    top: 80px;
                    max-width: 476px;
                    width: 100%;
                }
            }
        }
    }

    .summary-window-active {
        height: 440px;
        box-shadow: 0px 0px 10px #99999950;
        display: block;
    }

    .app-header {
        display: none;

        &--wrapper {
            display: flex;

            @media (max-width: 550px) {
                flex-direction: column-reverse;
                align-items: flex-end;
            }
        }

        img {
            background: #fff;
        }

        @media (max-width: 1200px) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background-color: #fff;
            z-index: 10;
            padding: 20px 2rem;
            height: 80px;
            box-shadow: 0px 0px 10px #99999950;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--summary {
            color: #212529;
            text-decoration: none;
            border-width: 0px;
            background-color: transparent;
            background-image: url("./assets/shop-icon.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 32px;
            width: 32px;
            height: 24px;
            margin-left: 16px;

            &:hover {
                color: #212529;
                text-decoration: none;
            }

            @media (min-width: 1200px) {
                display: none;
            }

            &--active {
                background-image: url("./assets/close-icon.svg");
            }
        }
    }

    .app-content {
        position: relative;

        &--lock {
            &::before {
                content: '';
                position: absolute;
                inset: 0;
                z-index: 3;
                background: #fff;
                opacity: 0.5;
            }
        }
    }

    .app-logo {
        height: 80px;
        margin: 2rem 0 0 3rem;

        @media (max-width: 1200px) {
            display: none;
        }
    }

    .app-logo-header {
        margin: 0;
        height: 40px;
    }

    h2,
    h1 {
        font-weight: 300;
    }

    .app-header-desktop {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .app-header-basket {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    .app-security {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100dvh;

        &__form {
            position: relative;
        }

        &__logo {
            display: block;
            margin: 0 auto;
            height: auto;
        }

        &__header {
            text-align: center;
            margin-bottom: 3rem;
            padding: 0 1.5rem;
        }

        .btn {
            display: block;
            margin: 0 auto;
            width: 120px;
        }

        .card {
            box-shadow: 0px 0px 10px #99999950;
            border-width: 0;
        }

        [aria-invalid="true"]{
            border-color: #dc3545;
            & + .invalid-feedback{
                display: block;
            }
        }
    }

    .app-price-buttons {
        margin-left: auto;
        margin-top: 4px;

        @media (min-width: 551px) {
            margin-right: 32px;
            margin-top: 0px;
        }

        @media (min-width: 1200px) {
            margin-top: 32px;
            margin-right: 48px;
        }

        button {
            background-color: transparent !important;
            border: 1px solid #212529 !important;
            color: #212529 !important;
            font-size: 14px;

            @media (min-width: 1200px) {
                font-size: 18px;
            }
        }

        .btn-active {
            background-color: #212529 !important;
            color: white !important;
        }
    }
}