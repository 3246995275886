.summary {
    //position: sticky;
    //top: 0;
    padding: 2rem;
    background: #fff;
    font-size: 12px;

    @media (min-width: 768px) {
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--wrapper {
        //position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;

        /*@media (min-width: 768px) {
            width: 41%;
            position: fixed;
        }*/

        @media (min-width: 1200px) {
            width: 33%;
            position: fixed;
        }

        &::after {
            content: '';
            position: absolute;
            top: 1.5rem;
            bottom: 1.5rem;
            left: 0;
            width: 1px;
            background: rgba(#333, 0.2);
            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }
    }

    h2 {
        text-align: center;
    }

    .form-floating {

        &>input,
        textarea {
            border-radius: 8px;
            border: 1px solid rgba(#333, 0.2);

            &:focus-within {
                outline: 1px solid #121a42;
                box-shadow: none;
            }
        }
    }

    .table {
        margin-bottom: 2rem;

        tfoot {
            text-align: left;

            th:first-child {
                border-bottom: none;
                box-shadow: none;
            }
        }
    }

    .form-check {
        padding-left: 1.5rem;
        line-height: 1rem;
        position: relative;
        display: flex;
        align-items: center;

        label {
            cursor: pointer;
        }

        &--error {
            input+label::before {
                border-color: rgba(var(--bs-danger-rgb), 1) !important;
            }
        }

        input {
            display: none;

            &:checked+label {
                &::before {
                    background: #353e6d;
                    box-shadow: inset 0px 0px 0px 2px #fff;
                }
            }

            &+label {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 1rem;
                    height: 1rem;
                    display: block;
                    background: #fff;
                    border: 1px solid rgba(#333, 0.2);
                    border-radius: 4px;
                }
            }
        }
    }
}