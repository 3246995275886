.product-groups {
    padding: 0 3rem;

    @media (max-width: 1200px) {
        padding: 80px 2rem 0 2rem;
    }

    &>h1 {
        margin: 2rem 0;
    }
}